import { IconName } from '@fortawesome/fontawesome-svg-core';
import React from 'react';
import styled from 'styled-components';

import { Emoji } from '../../../../types/Emoji';
import { mediaBreakpointPxMd, mediaBreakpointPxXl } from '../../../styled/Breakpoint';
import { fontSm4 } from '../../../styled/TypeSystem';
import Badge from '../../display/badge';
import Icon from '../../display/icons/Icon';
import Link from '../../Link';
import { TruncatedText } from '../../Triage/TruncatedText';

const FlexWrapper = styled.div`
  align-items: center;
  display: flex;
  gap: ${({ theme: { constants } }) => constants.spacerSm1};
  overflow: hidden;
`;

// DS Override: Use the color as described in the designs
const StyledIcon = styled(Icon)`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  margin-right: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const EmojiWrapper = styled.div`
  display: flex;
  color: ${({ theme: { vars } }) => vars.textDefault};
  margin-inline: ${({ theme: { constants } }) => constants.spacerSm3};
`;

const ParentTitle = styled.p`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  max-width: 5rem;
  ${TruncatedText};
  @media (min-width: ${mediaBreakpointPxXl}) {
    max-width: 10rem;
  }
  ${fontSm4};
`;

const Separator = styled.p`
  padding: ${({ theme: { constants } }) => `0 ${constants.spacerSm3}`};
  color: ${({ theme: { vars } }) => vars.textSubdued};
  ${fontSm4};
`;

// DS Override: Prevent truncation
const StyledBadge = styled(Badge)`
  flex-shrink: 0;
`;

const ChildTitle = styled.div`
  max-width: 11.687rem;
  ${TruncatedText};
  @media (min-width: ${mediaBreakpointPxMd}) {
    max-width: 30rem;
  }
  ${fontSm4};
`;

export interface Props {
  id: string;
  child: {
    emoji: Emoji;
    term?: string;
    title: string;
    iconName: IconName;
  };
  parent: {
    emoji: Emoji;
    title: string;
    iconName: IconName;
    href: string;
  };
}

/**
 * Component for rendering a top navigation breadcrumb with parent and child elements.
 * It is agnostic of business logic and is a controlled component.
 * @param id - The unique identifier for the breadcrumb.
 * @param child - An object containing information about the child element, including emoji, term, title, and icon name.
 * @param parent - An object containing information about the parent element, including emoji, title, icon name, and href.
 * @returns JSX element representing the top navigation breadcrumb.
 */
const TopNavBreadcrumb = ({ id, child, parent }: Props) => {
  return (
    <FlexWrapper id={id}>
      <Link
        href={parent.href}
        text={
          <FlexWrapper as='span'>
            <StyledIcon name='arrow-left' />
            <EmojiWrapper>
              {parent.emoji ? parent.emoji : <Icon name={parent.iconName} />}
            </EmojiWrapper>
            <ParentTitle>{parent.title}</ParentTitle>
          </FlexWrapper>
        }
        underlineBehavior='dynamic'
      />
      <Separator>/</Separator>
      <FlexWrapper as='span'>
        {child.term && <StyledBadge text={child.term} type='info' />}
        <EmojiWrapper>{child.emoji ? child.emoji : <Icon name={child.iconName} />}</EmojiWrapper>
        <ChildTitle>{child.title}</ChildTitle>
      </FlexWrapper>
    </FlexWrapper>
  );
};

export default TopNavBreadcrumb;

import React from 'react';
import styled, { css } from 'styled-components';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import usePublicConfigs from '../../../../../hooks/usePublicConfigs';
import { useAbilityToEditUser } from '../../../../../hooks/users/ProfileSharedAbilities';
import { TrainingPathIllustration } from '../../../../../lib/gcsImages';
import initTranslations from '../../../../../lib/initTranslations';
import { userFirstName } from '../../../../../lib/userNaming';
import BasicErrorDisplay from '../../../../../redux/errors/BasicErrorDisplay';
import { useGetTrainingPathQuery } from '../../../../../redux/services/resourceApis/trainingPaths/trainingPathsApi';
import { useGetUserProgressQuery } from '../../../../../redux/services/resourceApis/users/usersApi';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import Link from '../../../../design_system/Link';
import NoResults from '../../../../design_system/Triage/NoResults';
import { deprecated, fontSm5 } from '../../../../styled/TypeSystem';
import { routes } from '../../../publicApplication/applicationRouter';
import RouterLink from '../../../publicApplication/RouterLink';
import ProgressBlock from './ProgressBlock/ProgressBlock';

export interface TrainingPathProps {
  userId: number;
}

const Container = styled.section`
  ${({ theme: { constants, vars } }) => css`
    display: flex;
    flex-direction: column;
    background: ${vars.foundationSurface1};
    border: ${constants.borderWidthSm} solid ${vars.foundationBase1};
    box-shadow: ${vars.shadowTopMedium};
    border-radius: ${constants.borderRadiusMd};
    padding: ${constants.spacerMd3};
    font-weight: ${constants.fontRegular};
    color: ${vars.textDefault};
    gap: ${({ theme: { constants } }) => constants.spacerMd2};
  `};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Title = styled.div`
  display: flex;
  ${deprecated.fontMd2};
`;

const Description = styled.p`
  color: ${({ theme: { vars } }) => vars.textSubdued};
  margin: 0;
  ${fontSm5};
`;

const Details = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.constants.spacerMd3};
`;

const Footer = styled.div`
  display: flex;
  justify-content: end;
  ${fontSm5};
`;

const t = initTranslations('user_profile.training_path_profile_card');

const TrainingPath = ({ userId }: TrainingPathProps) => {
  const { data: user, error } = useGetUserProgressQuery(userId);
  const { data: trainingPathData } = useGetTrainingPathQuery({ userId });
  const { slug } = useCurrentAccount();
  const { configs } = usePublicConfigs();
  const helpPageUrl = configs['TRAINING_PATH_HELP_PAGE'];
  const ableToEditUser = useAbilityToEditUser(userId);

  if (error) return <BasicErrorDisplay error={error} />;
  if (!user || !trainingPathData) return null;

  const { isPublished } = trainingPathData;

  return (
    <Container id='profile-training-path-card'>
      <Header>
        <Title>{t('title')}</Title>
        {ableToEditUser && (
          <DefaultButton
            behavesAs='a'
            buttonType='secondary'
            href={routes.userTrainingPathConfig({ slug, id: userId }).href}
            id='customize-training-path'
            size='sm'
            text={isPublished ? t('customize') : t('get_started')}
          />
        )}
      </Header>

      {isPublished ? (
        <Details>
          <Description>
            {ableToEditUser
              ? t('description_ability_to_manage_profile', { name: user.name })
              : t('description')}
            {t('space')}
            <Link
              behavesAs='button'
              onClick={() => window.open(helpPageUrl, '_blank')}
              text={t('learn_more')}
            />
          </Description>
          <ProgressBlock {...user} />
          <Footer>
            <RouterLink id='see-training-path' to={routes.userTrainingPath({ slug, id: userId })}>
              {t('link_text')}
            </RouterLink>
          </Footer>
        </Details>
      ) : (
        <NoResults
          className='user-profile-training-path-no-results'
          darkImage={TrainingPathIllustration}
          heading={t('empty_state.header')}
          iconWidth='30%'
          lightImage={TrainingPathIllustration}
          minHeight='unset'
          showBackground={false}
          showBorder={false}
          subHeaderText={
            ableToEditUser
              ? t('empty_state.sub_header', { name: userFirstName(user.name) })
              : undefined
          }
        />
      )}
    </Container>
  );
};

export default TrainingPath;

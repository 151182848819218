import React from 'react';

import useCurrentAccount from '../../../../../hooks/useCurrentAccount';
import { OutlineCourseLabelIcon } from '../../../../../lib/gcsImages';
import initTranslations from '../../../../../lib/initTranslations';
import { routes } from '../../../publicApplication/applicationRouter';
import RouteTo from '../../../publicApplication/routeTo';
import {
  CourseOutlineBadge,
  CourseOutlineButton,
  CourseOutlineEmoji,
  CourseOutlineInner,
  CourseOutlineLabel,
  CourseOutlineLabelText,
  CourseOutlineTitle,
  CourseOutlineTitleWrapper,
  CourseOutlineWrapper,
} from './styles';
import { CourseOutlineProps } from './types';

const t = initTranslations('courses_and_templates.courses.course_outline');

const CourseOutline = ({ id, categorySuite, title }: CourseOutlineProps) => {
  const { slug } = useCurrentAccount();
  // TODO GS3: Update route when we'll have it
  const coursePath = routes.template({ slug, id });

  return (
    <CourseOutlineWrapper>
      <CourseOutlineEmoji>{t(`emoji.${categorySuite}`)}</CourseOutlineEmoji>
      <CourseOutlineInner>
        <CourseOutlineTitleWrapper>
          <CourseOutlineTitle to={coursePath}>{title}</CourseOutlineTitle>
        </CourseOutlineTitleWrapper>
        <CourseOutlineLabel>
          {/*TODO GS3: To replace it after https://github.com/trainual/trainual/pull/20355 will be merged*/}
          <img alt={t('premium_courses_icon')} src={OutlineCourseLabelIcon} />
          <CourseOutlineLabelText>{t('premium_courses')}</CourseOutlineLabelText>
        </CourseOutlineLabel>
      </CourseOutlineInner>
      <CourseOutlineBadge categorySuite={categorySuite}>
        {t(`badge.${categorySuite}`)}
      </CourseOutlineBadge>
      <CourseOutlineButton
        buttonType='tertiary'
        id={`course-outline-button-${id}`}
        onClick={() => RouteTo(coursePath)}
        text={t('preview')}
      />
    </CourseOutlineWrapper>
  );
};

export default CourseOutline;

import styled from 'styled-components';

import DefaultButton from '../../../design_system/buttons/DefaultButton';
import { fontSm2, fontSm4, fontSm5 } from '../../../styled/TypeSystem';

export const PaywallCard = styled.div`
  background-color: ${({ theme: { vars } }) => vars.trainualBrandPurpleSurfaceLight};
  width: 15rem;
  border-radius: ${({ theme: { constants } }) => constants.borderRadiusXl};
  border: ${({ theme: { constants } }) => constants.borderWidthXl} solid
    ${({ theme: { vars } }) => vars.trainualBrandPurpleSurface};
`;

export const TrainualPlusBadge = styled.img`
  max-width: 18.25rem;
  margin-bottom: ${({ theme: { constants } }) => `-${constants.spacerLg3}`};
  display: block;
  align-self: center;
  z-index: 9999;
`;

export const PaywallWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FooterWrapper = styled.div`
  text-align: center;
  width: 100%;
  padding: ${({ theme: { constants } }) => `0 ${constants.spacerMd2} ${constants.spacerSm3}`};
`;

// DS Override: Extending width of button to match designs
export const StyledDefaultButton = styled(DefaultButton)`
  width: 100%;
`;

export const FooterCTA = styled.p`
  color: ${({ theme: { vars } }) => vars.trainualBrandPurpleStrong};
  margin: ${({ theme: { constants } }) => `0 ${constants.spacerMd2}`};
  font-weight: ${({ theme: { constants } }) => constants.fontBold};
  ${fontSm5}
`;

export const PaywallBody = styled.p`
  text-align: center;
  padding-top: ${({ theme: { constants } }) => constants.spacerLg2};
  margin: ${({ theme: { constants } }) => `${constants.spacerSm3} ${constants.spacerMd2}`};
`;

export const PaywallMessage = styled.p<{ canUpgrade: boolean }>`
  font-weight: ${({ theme: { constants }, canUpgrade }) =>
    canUpgrade ? constants.fontSemibold : constants.fontMedium};
  ${({ canUpgrade }) => (canUpgrade ? fontSm4 : fontSm2)}
`;

import styled, { css } from 'styled-components';

import { getCourseBadgeColor } from '../../../../../lib/marketplaces/templates_and_courses/getCourseBadgeColor';
import DefaultButton from '../../../../design_system/buttons/DefaultButton';
import {
  MultilineTruncatedText,
  TruncatedText,
} from '../../../../design_system/Triage/TruncatedText';
import { mediaBreakpointPxLg, mediaBreakpointPxSm } from '../../../../styled/Breakpoint';
import { fontMd1, fontSm2, fontSm4 } from '../../../../styled/TypeSystem';
import RouterLink from '../../../publicApplication/RouterLink';
import { CategorySuite } from './types';

export const CourseOutlineWrapper = styled.div`
  ${({ theme: { constants, vars } }) => css`
    display: flex;
    align-items: center;
    padding: ${constants.spacerMd2};
    border-radius: ${constants.borderRadiusLg};
    background-color: ${vars.foundationSurface1};
    border: ${constants.borderWidthSm} solid ${vars.borderSurface1};
  `};
`;

export const CourseOutlineEmoji = styled.div`
  ${({ theme: { constants, vars } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    min-width: 2rem;
    height: ${constants.heightSm};
    border-radius: ${constants.borderRadiusLg};
    background-color: ${vars.foundationBase1};
    border: ${constants.borderWidthSm} solid ${vars.borderSurface1};
  `};
`;

export const CourseOutlineInner = styled.div`
  ${({ theme: { constants } }) => css`
    margin: 0 ${constants.spacerMd2};
    min-width: 16rem;
    max-width: 60%;
    width: 100%;
  `};

  @media (max-width: ${mediaBreakpointPxLg}) {
    max-width: 45%;
    min-width: 8rem;
  }
`;

export const CourseOutlineTitleWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const CourseOutlineTitle = styled(RouterLink)`
  ${({ theme: { constants, vars } }) => css`
    display: block;
    font-weight: ${constants.fontMedium};
    color: ${vars.textDefault};
  `};
  ${TruncatedText({})};
  ${fontMd1};

  @media (max-width: ${mediaBreakpointPxLg}) {
    ${MultilineTruncatedText({})};
  }
`;

export const CourseOutlineLabel = styled.div`
  display: flex;
`;

export const CourseOutlineLabelText = styled.span`
  ${({ theme: { constants, vars } }) => css`
    margin-left: ${constants.spacerSm2};
    font-weight: ${constants.fontMedium};
    color: ${vars.textSubdued};
  `};
  ${fontSm4};
`;

export const CourseOutlineBadge = styled.div<{ categorySuite: CategorySuite }>`
  ${({ theme: { constants, vars }, categorySuite }) => css`
    height: 1.25rem;
    padding: ${constants.spacerSm2} ${constants.spacerSm3};
    margin-right: ${constants.spacerMd2};
    font-weight: ${constants.fontMedium};
    color: ${vars.textDefault};
    background-color: ${getCourseBadgeColor(categorySuite, vars)};
    border-radius: ${constants.borderRadiusEndcap};
  `};
  ${TruncatedText({})};
  ${fontSm2};
`;

export const CourseOutlineButton = styled(DefaultButton)`
  margin-left: auto;

  // Added below styles  according to the same fix (DEVOPS-992) for TemplateOutlineRowViewButton related to the multilanguage
  &:has(font) {
    min-width: auto;
  }

  @media (max-width: ${mediaBreakpointPxSm}) {
    margin: unset;
  }
`;

import { ColorTokens } from 'saguaro';

import { CategorySuite } from '../../../components/application/marketplaces/CoursesAndTemplates/CourseOutline/types';

export function getCourseBadgeColor(category: CategorySuite, vars: ColorTokens) {
  switch (category) {
    case CategorySuite.CybersecurityDataPrivacy:
      return vars.stateBackgroundError;
    case CategorySuite.DiversityInclusion:
      return vars.trainualBrandBlueSurfaceLight;
    case CategorySuite.EthicalPractices:
      return vars.trainualBrandGreenSurfaceLight;
    case CategorySuite.HarassmentDiscrimination:
      return vars.trainualBrandPurpleSurface;
    case CategorySuite.Other:
      return vars.stateBadgeGeneral;
    case CategorySuite.SoftSkills:
      return vars.trainualBrandYellowSurfaceLight;
    case CategorySuite.Wellness:
      return vars.trainualBrandMagentaSurfaceLight;
    case CategorySuite.WorkplaceSafety:
      return vars.stateBackgroundCaution;
  }
}

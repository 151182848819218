import React from 'react';

import { useStepId } from '../../../../contexts/StepIdContext';
import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import { useGetEditorTopicQuery } from '../../../../redux/services/resourceApis/courses/topicsApi';
import { useGetStepQuery } from '../../../../redux/services/resourceApis/steps/stepsApi';
import { useAccountTerminology } from '../../../AccountTerminologyProvider';
import TopNavBreadcrumb from '../../../design_system/navigation/TopNavBreadcrumb/TopNavBreadcrumb';
import { routes } from '../../publicApplication/applicationRouter';
import { SkeletonLoader } from '../../shared/SkeletonLoader/styles';

const Breadcrumb = () => {
  const { slug } = useCurrentAccount();
  const {
    step: { singular: term },
  } = useAccountTerminology();

  const stepId = useStepId();
  const { data: step } = useGetStepQuery(stepId);
  const courseId = step?.courseId;
  const { data: course } = useGetEditorTopicQuery(
    { courseId: courseId as number },
    { skip: !courseId }
  );

  if (!step || !course) return <SkeletonLoader width='16rem' />;

  return (
    <TopNavBreadcrumb
      child={{
        emoji: step.emoji,
        term,
        title: step.title,
        iconName: 'list-ol',
      }}
      id='step-breadcrumb'
      parent={{
        emoji: course.emoji,
        title: course.title,
        href: routes.editor({ id: stepId, slug }).href,
        iconName: 'file-lines',
      }}
    />
  );
};

export default Breadcrumb;

import React from 'react';
import { Route } from 'type-route';

import { StepIdProvider } from '../../../../contexts/StepIdContext';
import { routes } from '../../publicApplication/applicationRouter';
import FullScreenOverlay from '../../shared/FullScreenOverlay/FullScreenOverlay';
import { TiptapCollaborationProvider } from '../contexts/TiptapCollaboration';
import Breadcrumb from './Breadcrumb';
import Editor from './Editor';
import { Header, PageWrapper } from './styles';

interface Props {
  route: Route<typeof routes.stepVersionHistory>;
}

const Page = ({ route }: Props) => {
  const stepId = route.params.id;

  return (
    <FullScreenOverlay id='stepVersionHistory' withOpenedModal withoutCloseIcon>
      <PageWrapper>
        <StepIdProvider stepId={stepId}>
          <Header>
            <Breadcrumb />
          </Header>
          <TiptapCollaborationProvider stepId={stepId}>
            <Editor />
          </TiptapCollaborationProvider>
        </StepIdProvider>
      </PageWrapper>
    </FullScreenOverlay>
  );
};

export default Page;

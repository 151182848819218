import React from 'react';

import CourseOutline from '../CourseOutline/CourseOutline';
import { CoursesWrapper } from './styles';
import { CoursesProps } from './types';

const Courses = ({ courses }: CoursesProps) => {
  return (
    <CoursesWrapper>
      {courses.map((courses) => (
        <CourseOutline {...courses} key={courses.id} />
      ))}
    </CoursesWrapper>
  );
};

export default Courses;

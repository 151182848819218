import { THistoryVersionPreviewAction } from '@hocuspocus/provider';
import { CollabHistoryVersion } from '@tiptap-pro/extension-collaboration-history';
import React, { useCallback, useMemo } from 'react';

import { renderFormattedDate } from '../../../../lib/dateUtils';
import initTranslations from '../../../../lib/initTranslations';
import { groupVersions, sortedGroupKeys } from '../../../../lib/versionHistoryUtils';
import { useTiptapCollaboration } from '../contexts/TiptapCollaboration';
import PaywallBanner from './PaywallBanner';
import {
  CurrentVersion,
  Version,
  VersionGroup,
  VersionName,
  VersionsList,
  VersionsWrapper,
} from './styles';

interface Props {
  selectedVersion?: CollabHistoryVersion;
  setSelectedVersion: (version: CollabHistoryVersion) => void;
  versions: CollabHistoryVersion[];
}

const t = initTranslations('editor.version_history');

const todayLabel = t('today');
const yesterdayLabel = t('yesterday');

const Versions = ({ selectedVersion, setSelectedVersion, versions }: Props) => {
  const { provider } = useTiptapCollaboration();

  const handleVersionChange = useCallback(
    (version: CollabHistoryVersion) => {
      if (!provider) return;

      setSelectedVersion(version);

      const action: THistoryVersionPreviewAction = {
        action: 'version.preview',
        version: version.version,
      };
      provider.sendStateless(JSON.stringify(action));
    },
    [provider, setSelectedVersion]
  );

  const groupedVersions = useMemo(
    () => groupVersions(versions, todayLabel, yesterdayLabel),
    [versions]
  );

  const sortedKeys = useMemo(
    () => sortedGroupKeys(groupedVersions, todayLabel, yesterdayLabel),
    [groupedVersions]
  );

  return (
    <VersionsWrapper>
      {sortedKeys.map((group, groupIndex) => (
        <VersionsList key={group}>
          <VersionGroup>{group}</VersionGroup>
          {groupedVersions[group]
            .slice()
            .reverse()
            .map((version, versionIndex) => (
              <div key={version.version} onClick={() => handleVersionChange(version)}>
                <Version isSelected={selectedVersion?.version === version.version}>
                  {renderFormattedDate(version.date)}
                  {groupIndex === 0 && versionIndex === 0 && (
                    <CurrentVersion>{t('current_version')}</CurrentVersion>
                  )}
                  <VersionName>{version.name}</VersionName>
                </Version>
              </div>
            ))}
        </VersionsList>
      ))}
      <PaywallBanner />
    </VersionsWrapper>
  );
};

export default Versions;

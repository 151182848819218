export enum CategorySuite {
  CybersecurityDataPrivacy = 'cybersecurity_data_privacy',
  DiversityInclusion = 'diversity_inclusion',
  EthicalPractices = 'ethical_practices',
  HarassmentDiscrimination = 'harassment_discrimination',
  Other = 'other',
  SoftSkills = 'soft_skills',
  Wellness = 'wellness',
  WorkplaceSafety = 'workplace_safety',
}

export type CourseOutlineProps = {
  id: number;
  title: string;
  categorySuite: CategorySuite;
};

import React from 'react';

import { PowerUpNoSplash } from '../../../../lib/gcsImages';
import initTranslations from '../../../../lib/initTranslations';
import {
  FooterCTA,
  FooterWrapper,
  PaywallBody,
  PaywallCard,
  PaywallWrapper,
  StyledDefaultButton,
  TrainualPlusBadge,
} from './styles';

export type Props = {
  canUpgrade: boolean;
  ctaBody: JSX.Element;
  onClick: () => void;
};

const t = initTranslations('trainual_plus_paywall');

const TrainualPlusPaywall = ({ canUpgrade, ctaBody, onClick }: Props) => {
  return (
    <PaywallWrapper>
      <TrainualPlusBadge src={PowerUpNoSplash} />
      <PaywallCard>
        <PaywallBody>{ctaBody}</PaywallBody>
        <FooterWrapper>
          {canUpgrade ? (
            <StyledDefaultButton
              buttonType='brand'
              id='paywalled-banner-btn'
              onClick={onClick}
              text={t('buy_now')}
            />
          ) : (
            <FooterCTA>{t('footer_cta')}</FooterCTA>
          )}
        </FooterWrapper>
      </PaywallCard>
    </PaywallWrapper>
  );
};

export default TrainualPlusPaywall;

import React from 'react';

import initTranslations from '../../../../lib/initTranslations';
import { useGetEasyllamaTrainingsQuery } from '../../../../redux/services/resourceApis/easyllama/easyllamaApi';
import PageHeader from '../../../design_system/Triage/headers/PageHeader';
import PageContent from '../../shared/page_content';
import Courses from './Courses/Courses';

const t = initTranslations('courses_and_templates');

const CoursesAndTemplates = () => {
  const { data: easyllamaTrainingsData } = useGetEasyllamaTrainingsQuery();
  return (
    <PageContent>
      <PageHeader subtitle={t('subtitle')} title={t('title')} />
      {easyllamaTrainingsData ? (
        <Courses courses={easyllamaTrainingsData.trainings} />
      ) : (
        // TODO GS3: Add skeleton loader
        'Loading...'
      )}
    </PageContent>
  );
};

export default CoursesAndTemplates;

import { toCamelCase } from '../../../../lib/keyFormatConverter';
import { trainualApi } from '../../trainualService';
import { EasyllamaTrainingsResponse } from './types';

const easyllamaApi = trainualApi.injectEndpoints({
  endpoints: (builder) => ({
    getEasyllamaTrainings: builder.query<EasyllamaTrainingsResponse, void>({
      query: () => ({ url: 'ajax/easyllama/trainings' }),
      transformResponse: (response: EasyllamaTrainingsResponse) => {
        return toCamelCase(response);
      },
    }),
  }),
});

export const { useGetEasyllamaTrainingsQuery } = easyllamaApi;

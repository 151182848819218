import React from 'react';

import useCurriculumElement from '../../../../../hooks/useCurriculumElement';
import { ContentType } from '../../../../../types/ContentType';
import { ElementKind } from '../../../../../types/CurriculumElement';
import { Emoji } from '../../../../../types/Emoji';
import Breadcrumb from '../../../../design_system/navigation/TopNavBreadcrumb/TopNavBreadcrumb';

type Props = {
  contentType: ContentType;
  contentTypeTitle: string;
  emoji: Emoji;
  elementId: number;
  subjectEmoji: Emoji;
  subjectHref: string;
  subjectTitle: string;
};

const TopNavBreadcrumb = ({
  contentType,
  contentTypeTitle,
  elementId,
  subjectTitle,
  subjectEmoji,
  subjectHref,
  emoji,
}: Props) => {
  const elementAttributes = useCurriculumElement();
  const { termSingular, iconName } = elementAttributes({
    elementId,
    elementKind: contentType as ElementKind,
  });

  return (
    <Breadcrumb
      child={{
        emoji,
        term: termSingular,
        title: contentTypeTitle,
        iconName,
      }}
      id='top-nav-breadcrumb'
      parent={{
        emoji: subjectEmoji,
        title: subjectTitle,
        href: subjectHref,
        iconName: 'file-lines',
      }}
    />
  );
};

export default TopNavBreadcrumb;

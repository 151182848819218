import React from 'react';

import useCurrentAccount from '../../../../hooks/useCurrentAccount';
import useCurrentUser from '../../../../hooks/useCurrentUser';
import initTranslations from '../../../../lib/initTranslations';
import { PaywallMessage } from '../../shared/TrainualPlusPaywall/styles';
import TrainualPlusPaywall from '../../shared/TrainualPlusPaywall/TrainualPlusPaywall';

const t = initTranslations('trainual_plus_paywall');

const PaywallBanner = () => {
  const { permission } = useCurrentUser();
  const { nonBilling, hasTrainualPlusFeatures } = useCurrentAccount();
  const isBillingAdmin = permission === 'billing_admin';

  return (
    <>
      {!nonBilling && !hasTrainualPlusFeatures ? (
        <TrainualPlusPaywall
          canUpgrade={isBillingAdmin}
          ctaBody={
            <PaywallMessage
              canUpgrade={isBillingAdmin}
              dangerouslySetInnerHTML={{ __html: t('vh_pre_timeframe') }}
            />
          }
          onClick={() => {}}
        />
      ) : null}
    </>
  );
};

export default PaywallBanner;
